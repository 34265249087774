import React from "react"
import { graphql } from "gatsby"
import { Wysiwyg } from "components/helpers"
import vague from "../../images/vague.webp"

import "scss/paragraphs/lists.scss"

const Lists = ({
  field_title,
  field_posttitle,
  field_description,
  relationships: { field_list_detail },
}) => (
  <div className="lists" data-sal-duration="1500" data-sal="zoom-in">
    <h3 className="lists__heading">
      <span
        className="overline"
        style={{
          backgroundImage: `url(${vague})`
        }}
      ></span>
      {field_title} <span className="sub__title">{field_posttitle}</span>
    </h3>
    <Wysiwyg className="lists__copy" {...field_description} />
    <div className="list__wrapper">
      {field_list_detail.map(({ field_list_style, field_list_text }, index) => (
        <ul className={`list list--${field_list_style}`} key={index}>
          {field_list_text.map((text, index) => (
            <li className="list__item" key={index}>
              {field_list_text}
            </li>
          ))}
        </ul>
      ))}
    </div>
  </div>
)

export default Lists

export const query = graphql`
  fragment ListParagraph on paragraph__list {
    id
    internal {
      type
    }
    field_title
    field_posttitle
    field_description {
      processed
    }
    relationships {
      field_list_detail {
        field_list_style
        field_list_text
      }
    }
  }
`

import React from "react"
import { FormattedMessage } from "react-intl"
import { graphql, Link } from "gatsby"
import { Wysiwyg, Media } from "components/helpers"
import "scss/paragraphs/header.scss"
import { LocalizedLink } from "gatsby-theme-i18n"

const Header = ({
  field_link,
  field_project_name,
  field_subtitle,
  field_description,
  relationships: { field_image_media, field_technology_image },
}) => {
  return (
    <>
      <div className="case__header-wrapper">
        <div className="container">
          <div className="case__header">
            <div
              className="case__subheader"
              data-sal-duration="1500"
              data-sal="slide-right"
            >
              <div className="case__type">
                <FormattedMessage id="Case study" />
              </div>
              <div className="case__breadcrumb">
                <LocalizedLink className="case__breadcrumb" to={`/clients`}>
                  <FormattedMessage id="projects" />
                </LocalizedLink>
                <span className="case__breadcrumb-separator"> >> </span>
                <span>{field_project_name}</span>
              </div>
            </div>
            <div
              className="case__thumb"
              data-sal-duration="1500"
              data-sal="slide-left"
            >
              <Media field_image_media={field_image_media} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="case__site">
          <Link
            className="case__url cta__pink"
            to={field_link?.uri}
            title="Voir le site"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="see the site" />
            <div id="circle"></div>
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="case__intro">
          <div data-sal-duration="1500" data-sal="slide-right">
            <div className="case__title">{field_project_name}</div>
            <div className="case__subtitle">{field_subtitle}</div>
            <Wysiwyg className="case__details" {...field_description} />
          </div>
          <div
            className="case__technos-wrapper"
            data-sal-duration="1500"
            data-sal="slide-left"
          >
            {field_technology_image.map(({ id }, index) => {
              if (index % 2 !== 0) return null
              return (
                <div key={id} className="case__technos">
                  <Media
                    className="case__techno"
                    field_image_media={field_technology_image[index]}
                  />
                  <Media
                    className="case__techno"
                    field_image_media={field_technology_image[index + 1]}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header

export const query = graphql`fragment HeaderParagraph on paragraph__header {
  id
  internal {
    type
  }
  field_link {
    uri
  }
  field_project_name
  field_subtitle
  field_description {
    processed
  }
  relationships {
    field_image_media {
      field_media_image {
        alt
      }
      relationships {
        field_media_image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 208, width: 310, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    field_technology_image {
      id
      field_media_image {
        alt
      }
      relationships {
        field_media_image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 150, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`

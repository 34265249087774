import React from "react"
import { graphql } from "gatsby"
import { Wysiwyg } from "../helpers"

import "scss/paragraphs/presentation.scss"

const WysiwygPar = ({ field_description }) => (
  <div className="Wysiwyg container">
    <Wysiwyg {...field_description} />
  </div>
)

export default WysiwygPar

export const query = graphql`
  fragment WysiwygParagraph on paragraph__wysiwyg {
    id
    internal {
      type
    }
    field_description {
      processed
    }
  }
`

import React from "react"
import { graphql } from "gatsby"
import { Media } from "components/helpers"

import "scss/paragraphs/drupalAssociation.scss"

const DrupalPlatform = ({
  field_title,
  field_red_text,
  relationships: { field_media_image },
}) => (
  <>
    <div className="drupal-association">
      <div className="container">
        <div className="drupal-text platform-text">
          <div className="content-association content-platform">
            <h3>{field_title}</h3>
            <h4>{field_red_text}</h4>
          </div>
          <Media
            className="mediaObject__image"
            field_image_media={field_media_image}
          />
        </div>
      </div>
    </div>
  </>
)
export default DrupalPlatform
export const query = graphql`fragment PlatformBlockParagraph on paragraph__platform_block {
  id
  internal {
    type
  }
  field_title
  field_red_text
  relationships {
    field_media_image {
      field_media_image {
        alt
      }
      relationships {
        field_media_image {
          localFile {
            publicURL
            extension
            childImageSharp {
              gatsbyImageData(width: 202, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`

import React from "react"
import { graphql } from "gatsby"
import { Media } from "components/helpers"

import "scss/paragraphs/drupalAssociation.scss"

const DrupalAssociation = ({
  field_title,
  field_red_text,
  relationships: { field_media_image },
}) => (
  <>
    <div className="drupal-association">
      <div className="container">
        <div className="drupal-text">
          <div data-sal-duration="1500" data-sal="slide-right">
            <Media
              className="mediaObject__image"
              field_image_media={field_media_image}
            />
          </div>

          <div
            className="content-association"
            data-sal-duration="1500"
            data-sal="slide-left"
          >
            <h3>{field_title}</h3>
            <h4>{field_red_text}</h4>
          </div>
        </div>
      </div>
    </div>
  </>
)
export default DrupalAssociation
export const query = graphql`fragment DrupalAssociationParagraph on paragraph__drupal_association {
  id
  internal {
    type
  }
  field_title
  field_red_text
  relationships {
    field_media_image {
      field_media_image {
        alt
      }
      relationships {
        field_media_image {
          localFile {
            publicURL
            extension
            childImageSharp {
              gatsbyImageData(width: 202, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`

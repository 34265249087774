import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import { Anchor, Media, Wysiwyg } from "components/helpers"

import "scss/linksList.scss"
import "scss/paragraphs/mediaObject.scss"

const MediaObject = ({
  field_title,
  field_subtitle,
  field_media_position,
  field_description,
  field_simple_link,
  relationships: { field_media_image },
}) => (
  <div className={"mediaObject " + field_media_position}>
    <div
      className="mediaObject__image"
      data-sal-duration="1500"
      data-sal="slide-right"
    >
      <Media field_image_media={field_media_image} />
    </div>

    <div
      className="mediaObject__text"
      data-sal-duration="1500"
      data-sal="slide-left"
    >
      <h3>{field_title}</h3>
      <h4>{field_subtitle}</h4>
      <Wysiwyg {...field_description} />
      {field_simple_link && (
        <Anchor className="link" {...field_simple_link}>
          &gt; <FormattedMessage id="lire" />
        </Anchor>
      )}
    </div>
  </div>
)

export default MediaObject

export const query = graphql`fragment MediaObjectsParagraph on paragraph__medias_objects {
  id
  internal {
    type
  }
  field_media_position
  field_subtitle
  field_title
  field_simple_link {
    uri
  }
  field_description {
    processed
  }
  relationships {
    field_media_image {
      field_media_image {
        alt
      }
      relationships {
        field_media_image {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`

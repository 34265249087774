import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Layout from "components/layout"
import MediaObject from "components/paragraphs/mediaObjects"
import Lists from "components/paragraphs/lists"
//import Blogs from "components/paragraphs/blogs"
import Banners from "components/paragraphs/banners"
import Header from "components/paragraphs/header"
import DrupalAssociation from "components/paragraphs/drupalAssociation"
import PlatformBlock from "components/paragraphs/drupalPlatform"
import WysiwygPar from "components/paragraphs/wysiwyg"

const RicheTemplate = ({ data }) => {
  if (!data) return null

  const {
    nodeRich: {
      title,
      path: { alias },
      field_page_type,
      relationships: { field_paragraphs },
    },
  } = data

  const relationships = field_paragraphs.find((paragraph) =>
    field_page_type === "clients"
      ? paragraph?.internal?.type === "paragraph__header"
      : field_page_type === "services"
      ? paragraph?.internal?.type === "paragraph__medias_objects"
      : { relationships: {} }
  )?.relationships

  const metadata = {
    url: alias,
    title: title,
    description: "",
    image:
      relationships?.field_media_image?.relationships?.field_media_image
        ?.localFile?.publicURL ||
      relationships?.field_image_media?.relationships?.field_media_image
        ?.localFile?.publicURL,
  }

  return (
    <Layout>
      <SEO {...metadata} />
      <div className="richePage">
        {field_paragraphs.map((paragraph) => {
          if (!paragraph.internal) return null

          switch (paragraph.internal.type) {
            case "paragraph__header":
              return <Header {...paragraph} key={paragraph.id} />

            /*case "paragraph__blog":
              return (
                <div className="container">
                  <Blogs {...paragraph} />
                </div>
              )*/

            case "paragraph__banner":
              return <Banners {...paragraph} key={paragraph.id} />

            case "paragraph__medias_objects":
              return (
                <div className="container" key={paragraph.id}>
                  <MediaObject {...paragraph} />
                </div>
              )

            case "paragraph__list":
              return (
                <div className="container" key={paragraph.id}>
                  <Lists {...paragraph} />
                </div>
              )

            case "paragraph__drupal_association":
              return <DrupalAssociation {...paragraph} key={paragraph.id} />

            case "paragraph__platform_block":
              return <PlatformBlock {...paragraph} key={paragraph.id} />

            case "paragraph__wysiwyg":
              return <WysiwygPar {...paragraph} key={paragraph.id} />

            default:
              return null
          }
        })}
      </div>
    </Layout>
  )
}
/* query a ajouter en V2
 ... on paragraph__blog {
     ...BlogParagraph
 }*/

export const query = graphql`
  query riche($locale: String = "fr", $slug: String!) {
    nodeRich(langcode: { eq: $locale }, path: { alias: { eq: $slug } }) {
      title
      path {
        alias
      }
      field_page_type
      relationships {
        field_paragraphs {
          ... on paragraph__header {
            ...HeaderParagraph
          }
          ... on paragraph__banner {
            ...BannerParagraph
          }
          ... on paragraph__medias_objects {
            ...MediaObjectsParagraph
          }
          ... on paragraph__list {
            ...ListParagraph
          }

          ... on paragraph__drupal_association {
            ...DrupalAssociationParagraph
          }
          ... on paragraph__platform_block {
            ...PlatformBlockParagraph
          }
          ... on paragraph__wysiwyg {
            ...WysiwygParagraph
          }
        }
      }
    }
  }
`

export default RicheTemplate

import React from "react"
import { graphql } from "gatsby"
import { Media } from "components/helpers"

import "scss/paragraphs/banner.scss"

const Banners = ({
  field_title,
  field_position,
  relationships: { field_media_image },
}) => {
  return (
    <>
      <div className="banners">
        <div>
          <div
            data-sal-duration="1500"
            data-sal="zoom-out"
            className={`${
              field_position === "large" && "container"
            } banners__item`}
          >
            <Media field_image_media={field_media_image} />
            <h1 className="banners__item--text">{field_title}</h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banners

export const query = graphql`fragment BannerParagraph on paragraph__banner {
  id
  internal {
    type
  }
  field_title
  field_position
  relationships {
    field_media_image {
      field_media_image {
        alt
      }
      relationships {
        field_media_image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
